import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.scss'],
})
export class SettingComponent {
  @Input() startDescription: string = '';
  @Input() endDesciption: string = '';
  @Input() styleClasses: string = '';
}
