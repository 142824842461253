<div class="threshold">
  <div class="threshold-severity-title">
    <div ngClass="icon {{ icon }}">
      <mat-icon>{{ icon }}</mat-icon>
    </div>
    <div class="title">{{ title }}</div>
  </div>
  <div class="threshold-severity-content" [ngClass]="styleClasses">
    <ng-content></ng-content>
  </div>
</div>
