import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
})
export class CheckboxComponent implements ControlValueAccessor {
  @Input() isDisabled: boolean = false;
  isChecked: boolean = false;
  private onChange = (v: string) => {};
  private onTouched = () => {};
  private supportedValues = ['true', 'false'];

  writeValue(value: string | boolean): void {
    if (typeof value === 'string' && this.supportedValues.includes(value)) {
      this.isChecked = value === 'true';
    } else if (typeof value === 'boolean') {
      this.isChecked = value;
    } else {
      throw Error('Unsupport the type difference with string and boolean');
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onCheckboxChecked(event: MatCheckboxChange) {
    this.isChecked = event.checked;
    this.onTouched();
    this.onChange(this.isChecked + ''); // shoud be string
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

}
