//Angular
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';

// 3rd Party dependencies
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { ToastrModule } from 'ngx-toastr';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

//Zonar Pattern Library
import { ZonarUICoreModule } from '@zonar-ui/core';
import { ZonarOwnerIdInterceptor, ZonarUiAuthModule, ZonarUiPermissionsModule } from '@zonar-ui/auth';
import { SidenavModule } from '@zonar-ui/sidenav';
import { ZonarUIMaterialModule } from '@zonar-ui/material';
import { I18nService, TranslateService, ZonarUiI18nModule } from '@zonar-ui/i18n';
import { ZonarUiProgressSpinnerModule } from '@zonar-ui/progress-spinner';
import { ZonarUiFooterModule } from '@zonar-ui/footer';

// App-level Imports
import { environment } from '@environments/environment';
import { CoreModule } from '@app/modules/core/core.module';
import { Translations } from '@app/services/i18n/translations.service';
import { ResourceLoadState } from '@app/models/resource-load.state';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ThresholdSettingsComponent } from './views/threshold-settings/threshold-settings.component';
import { HomeComponent } from './views/home/home.component';
import { NotFoundComponent } from './views/not-found/not-found.component';
import { AppState } from './app.state';
import { CustomExpansionPanelComponent } from './components/custom-expansion-panel/custom-expansion-panel.component';
import { DynamicFormControlComponent } from './views/threshold-settings/dynamic-form-control/dynamic-form-control/dynamic-form-control.component';
import { ThresholdComponent } from './components/threshold/threshold.component';
import { EventComponent } from './components/event/event.component';
import { SettingComponent } from './components/setting/setting.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { ThrottleClickDirective } from './directives/throttle-click/throttle-click.directive';
import { NotificationComponent } from './modules/cts-notification/components/notification.component';
import { ErrorHandlerInterceptor } from './interceptors/error-handler/error-handler.interceptor';
import { CheckboxComponent } from './components/checkbox/checkbox.component';

export function loadAppTranslations(
  translateService: TranslateService,
  i18nService: I18nService,
  translations: Translations
): () => Observable<any> {
  return () => {
    return translateService
      .use(
        i18nService.getLanguageInUse()
      )
      .pipe(
        tap(() => {
          translateService.setDefaultLang(environment.i18n.defaultLanguage);
          translations.translationsLoadState.next(ResourceLoadState.LOAD_SUCCESSFUL);
        }),
        switchMap((lang) => of(lang))
      );
  };
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ThresholdSettingsComponent,
    NotFoundComponent,
    CustomExpansionPanelComponent,
    DynamicFormControlComponent,
    ThresholdComponent,
    EventComponent,
    SettingComponent,
    DialogComponent,
    ThrottleClickDirective,
    NotificationComponent,
    CheckboxComponent,
  ],
  imports: [
    AppRoutingModule,
    AuthModule.forRoot({
      audience: environment.auth.audience,
      clientId: environment.auth.clientID,
      domain: environment.auth.domain,
      redirect_uri: window.location.origin,
      scope: 'openid profile email',
      max_age: 36000,
      useRefreshTokens: environment.auth.useRefreshTokens,
      cacheLocation: 'localstorage',
      httpInterceptor: {
        allowedList: [
          `${environment.apiBase.url}/*`,
          `${environment.coreEntityApiBase.url}/*`,
          /* allowing beta2 is necessary for apis on beta versions (e.g., settings) */
          `${environment.coreEntityApiBase.url}beta2/*`,
          `${environment.userPreferenceApiBase.url}/*`,
        ],
      },
    }),
    BrowserAnimationsModule,
    BrowserModule,
    ReactiveFormsModule,
    ZonarUiAuthModule,
    ZonarUiPermissionsModule,
    ZonarUIMaterialModule,
    CoreModule,
    FlexLayoutModule,
    HttpClientModule,
    NgxSkeletonLoaderModule,
    SidenavModule.forRoot({ stage: environment.authEnv || null }),
    ZonarUICoreModule,
    ZonarUiI18nModule,
    ZonarUiProgressSpinnerModule,
    ZonarUiFooterModule,
    NgxsModule.forRoot([AppState]),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsLoggerPluginModule.forRoot(),
    ToastrModule.forRoot({
      toastComponent: NotificationComponent,
      timeOut: 10000,
      extendedTimeOut: 10000,
      positionClass: 'toast-bottom-right',
      toastClass: 'ngx-toastr',
      enableHtml: true,
      onActivateTick: true,
    }),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ZonarOwnerIdInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorHandlerInterceptor, multi: true },

    { provide: APP_INITIALIZER, useFactory: loadAppTranslations, deps: [TranslateService, I18nService, Translations], multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
