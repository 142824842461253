import { Component, Input } from '@angular/core';
import { ControlContainer, FormGroup, FormGroupDirective } from '@angular/forms';

export interface DynamicOption {
  display: string;
  value: string;
}

@Component({
  selector: 'app-dynamic-form-control',
  templateUrl: './dynamic-form-control.component.html',
  styleUrls: ['./dynamic-form-control.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class DynamicFormControlComponent {
  @Input() formGroup: FormGroup;
  @Input() formControlNameKey: string;
  @Input() typeControl: string;
  @Input() labelFor: string;
  @Input() validators: object;
  @Input() options: DynamicOption[] = [];
  @Input() isDisabled: boolean = false;
}
