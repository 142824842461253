<app-custom-expansion-panel [isExpanded]="event.isExpanded" [isDisabled]="isNoUserPermission" (expansionChange)="expansionChange.emit($event)">
  <div header-expansion-title class="event-name">{{ event.name }}</div>
  <div header-expansion-description class="event-description">{{ event.description }}</div>
  <div expansion-panel-content class="event-content">
    <div *ngIf="lastUpdatedTime" class="event-change-history">Changes made on {{ lastUpdatedTime }}</div>
    <form [formGroup]="formGroup" class="settings thresholds" (ngSubmit)="onSubmit()">
      <div class="setting-default">
        <mat-checkbox *ngIf="isConfigurableEvent" class="reset-checkbox" formControlName="useDefaultSettings" [disabled]="!userPermission?.manage"> Use Zonar default settings </mat-checkbox>
      </div>
      <app-threshold *ngFor="let threshold of event.thresholdsView" [icon]="threshold.icon" [title]="threshold.name" [styleClasses]="threshold.styleClasses">
        <app-setting *ngFor="let setting of threshold.settingsView" [startDescription]="setting.startDescription" [endDesciption]="setting.endDescription" [styleClasses]="setting.styleClasses">
          <app-dynamic-form-control
            *ngIf="setting.formControl as control"
            [formControlNameKey]="control.key"
            [formGroup]="formGroup"
            [typeControl]="control.type"
            [labelFor]="setting.labelFor"
            [options]="control.options"
            [validators]="control.validators"
            [isDisabled]="isSettingFieldDisabled"
          ></app-dynamic-form-control>
        </app-setting>
      </app-threshold>
      <div class="additional-note" *ngIf="event.footer">{{ event.footer }}</div>
      <div class="actions" *ngIf="userPermission?.manage && isConfigurableEvent">
        <button fxLayout="row" fxLayoutAlign="center center" type="button" class="cancel-button" mat-button [disabled]="isCancelButtonDisabled" (click)="onCancel()">Cancel</button>
        <button fxLayout="row" fxLayoutAlign="center center" type="submit" class="save-button" mat-button [disabled]="isSaveButtonDisabled">{{ isSaving ? 'Saving' : 'Save' }}</button>
      </div>
    </form>
  </div>
</app-custom-expansion-panel>
