// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  name: 'qa',
  production: false,
  region: 'NA',
  appUrl: 'settings.qa.zonarsystems.net',
  apiBase: {
    url: 'https://api.zonarsystems.net/core/v1/settings',
  },
  coreEntityApiBase: {
    url: 'https://api-qa.zonarsystems.net/core/v1',
  },
  userPreferenceApiBase: {
    url: 'https://api-qa.zonarsystems.net/alert/v1',
  },
  externalLinks: {
    alertPage: 'https://alertmanagement.qa.zonarsystems.net/',
  },
  authEnv: 'qa',
  auth: {
    clientID: 'zzkF875K0K7ESJKdEiQ2e911XQv9tIks',
    domain: 'qa-login.zonarsystems.net',
    audience: 'http://apiqa.zonarsystems.net/',
    applicationId: '19334f0c-89ff-4e7b-9017-3de175a16929',
    defaultZonarRole: '45a6f67f-ad4e-4faa-ba5f-3824853ef9e1',
    useRefreshTokens: true,
  },
  pendo: {
    url: 'https://cdn.pendo.io/agent/static/',
    key: '47e63a49-4477-47c2-4ea6-0f032e1785d5',
  },
  googleTagManager: {
    id: 'GTM-PM9R42P',
    auth: '2KLWSiRQSUBTl0bsYimfIQ',
    environment: 'env-4',
  },
  googleOptimize: {
    gOptId: null,
    powerOnAbTestId: null,
  },
  datadog: {
    enabled: true,
    applicationId: 'c600f2b7-494c-4e18-afc6-439707bcc61c',
    clientToken: 'pub9209625fca6265b4ba1bae7620e0f7b4',
    siteUrl: 'us5.datadoghq.com',
    tagEnvironment: 'qa',
  },
  i18n: {
    supportedLanguages: ['en', 'en-GB', 'de', 'es', 'fr', 'it'],
    defaultLanguage: 'en',
  },
  devCycle: {
    clientId: 'dvc_client_3a03d1aa_1354_432c_af11_23757d5b2e7a_f7b1a96',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
